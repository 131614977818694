import logo from './logo.svg';
import './App.css';
import Navbar from './conponent/navbar/Navbar';
import Header from './conponent/header/Header';
import ImageView from './conponent/imageview/ImageView';
import ImageScroll from './conponent/imagescroll/ImageScroll';
import Footer from './conponent/footer/Footer';
import Contact from './conponent/contact/Contact';

function App() {
  return (
    <div>

      <Header/>
      <ImageView/>
      <ImageScroll/>
      <Footer/>
      <Navbar/>

    </div>
  );
}

export default App;
