import React from 'react'
import './header.css'

function Header() {
  return (
    <div className='nbody'>
      
  <div class="content">
   <h1>
    Duniyape Technologies
   </h1>
   <p>
    Hello, Apple Intelligence.
   </p>
   <p class="availability">
    Available starting 10.23
   </p>
   {/* <div class="buttons">
    <a class="learn-more" href="#">
     Learn more
    </a>
    <a class="pre-order" href="#">
     Pre-order
    </a>
   </div> */}
   <div class="image-container">
    <img alt="Hands holding and using iPad mini with various apps displayed on the screen" height="400" src="img/demo.png"/>
   </div>
   <p class="footer">
    Apple Intelligence coming this fall
   </p>
  </div>
    </div>
  )
}

export default Header
