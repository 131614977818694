import React from 'react'
import './navbar.css'

function Navbar() {
  return (
<div className='nbody'>

    <div class="navbar">
    <a class="logo" href="/">
     <img src='img/logo.png' alt='' style={{
        width: 25
     }}/>
    </a>
    <a href="/">
     Home
    </a>
    <a href="/values">
    Values & Culture
    </a>
    <a href="/workflow">
    Workflow
    </a>
    <a href="/story">
    Our Story
    </a>
    <a href="/career">
     Careers
    </a>
    <a href="/contact">
     Contact Us
    </a>
    <div class="icons">
     <a href="#">
      <i class="fas fa-search">
      </i>
     </a>
     <a href="#">
      <i class="fas fa-shopping-bag">
      </i>
     </a>
    </div>
   </div>



    
    </div>
  )
}

export default Navbar
