import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { Routes,Route, BrowserRouter } from "react-router-dom";
import Contact from './conponent/contact/Contact';
import Career from './conponent/career/Career';
import Values from './conponent/values&culture/Values';
import Story from './conponent/story/Story';
import Workflow from './conponent/workflow/Workflow';
import Policy from './conponent/documents/Policy';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App/>}/>
        <Route path="/contact" element={<Contact/>}/>
        <Route path="/career" element={<Career/>}/>
        <Route path="/values" element={<Values/>}/>
        <Route path="/story" element={<Story/>}/>
        <Route path="/workflow" element={<Workflow/>}/>
        <Route path="/policy" element={<Policy/>}/>
      </Routes>
    </BrowserRouter>
    
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
