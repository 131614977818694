import React from 'react'
import Footer from '../footer/Footer'
import Navbar from '../navbar/Navbar'
import './workflow.css'

function Workflow() {
  return (
    <div>
<div className='wctitle'>
<div class="ctitle">Client Project Lifecycle</div>
<div class="csubtitle">Design and Delivery Process - Here's a breakdown of the stages:</div>
</div>


    <div className='workflowdiv'>
        <div className="workflow1">

            <img className='workflowimg' src='https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&w=1200' alt='img'/>

            <div className='workflowline'></div>

            <img className='workflowimg' src='https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&w=1200' alt='img'/>

            <div className='workflowline'></div>

            <img className='workflowimg' src='https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&w=1200' alt='img'/>

            <div className='workflowline'></div>

            <img className='workflowimg' src='https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&w=1200' alt='img'/>

        </div>
        <div className="workflow2">
            <h1 className='h1workflow'>Meeting</h1>
            <h1 className='h1workflow'>Planning</h1>
            <h1 className='h1workflow'>Execute</h1>
            <h1 className='h1workflow'>Delivery & Service</h1>
        </div>

        <div className="workflow3">
            <div className='pworkflow'>Initial discussion with the client to gather requirements and understand their needs.</div>
            <div className='pworkflow'>Gathering necessary resources (like images and content) and planning the design or project approach.</div>
            <div className='pworkflow'>The actual execution or development phase, where the design or service is created.</div>
            <div className='pworkflow'> Final delivery of the project or service, ensuring it aligns with the client's expectations, followed by ongoing support if required.</div>

        </div>
    </div>
  
    <Footer/>
  <Navbar/>
</div>
  )
}

export default Workflow
