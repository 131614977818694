import React from 'react'
import Navbar from '../navbar/Navbar'
import Footer from '../footer/Footer'
import './career.css'

function Career() {
  return (
    <div>



    <div className='ccontact'>

    <div class="ctitle">Join Us</div>
  <div class="csubtitle">Questions, bug reports, feedback – we’re here for it all.</div>


        <div className='ccontactdiv'>
        <img className='ccontactimg' src='https://images.pexels.com/photos/1391499/pexels-photo-1391499.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500' alt='img'/>
        <div style={{height:1,width:100,background:'black'}}></div>
        <div>Software Developer</div>
        </div>

        <div className='ccontactdiv1'>
        <img className='ccontactimg' src='https://images.pexels.com/photos/1391499/pexels-photo-1391499.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500' alt='img'/>
        <div style={{height:1,width:100,background:'black'}}></div>
        <div>Software Developer</div>
        </div>

        <div className='ccontactdiv2'>
        <img className='ccontactimg' src='https://images.pexels.com/photos/1391499/pexels-photo-1391499.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500' alt='img'/>
        <div style={{height:1,width:100,background:'black'}}></div>
        <div>Software Developer</div>
        </div>

        <div className='ccontactdiv3'>
        <div>Software Developer</div>
        <div style={{height:1,width:100,background:'black'}}></div>
        <img className='ccontactimg' src='https://images.pexels.com/photos/1391499/pexels-photo-1391499.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500' alt='img'/>
        </div>

        <div className='ccontactdiv4'>
        <div>Software Developer</div>
        <div style={{height:1,width:100,background:'black'}}></div>
        <img className='ccontactimg' src='https://images.pexels.com/photos/1391499/pexels-photo-1391499.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500' alt='img'/>
        </div>

        <div className='ccontactdiv5'>
        <div>Software Developer</div>
        <div style={{height:1,width:100,background:'black'}}></div>
        <img className='ccontactimg' src='https://images.pexels.com/photos/1391499/pexels-photo-1391499.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500' alt='img'/>
        </div>

      <div class="cdecorative-circle cdecorative-circle-1"></div>
  <div class="cdecorative-circle cdecorative-circle-2"></div>
  <div class="cdecorative-circle cdecorative-circle-3"></div>
  <div class="cdecorative-circle cdecorative-circle-4"></div>
  <div class="cdecorative-circle cdecorative-circle-5"></div>
  <div class="cdecorative-circle cdecorative-circle-6"></div>


<div class="ccontactcontainer">
  
      <div class="clocation">
          <h2>Graduates</h2>
          <p>You can ask us your query <br/>through email.</p>
          <a href="tel:+61292621443">Support@duniyape.in</a><br/>
          <a href="https://maps.google.com/?q=Level+6,+341+George+Street,+Sydney,+NSW+2000,+Australia" target="_blank">Go to email <i class="fas fa-external-link-alt"></i></a>
      </div>
      <div class="clocation">
          <h2>Experienced Professionals</h2>
          <p>You can also connect with us<br/> via WhatsApp.</p>
          <a href="tel:+918037012649">+91 6265 578 975</a><br/>
          <a href="https://maps.google.com/?q=3rd+Floor,+Sunriver,+Wework,+Embassy+Golf+Links+Business+Park,+Domlur,+Bangalore+-+560071" target="_blank">Open Whatsapp <i class="fas fa-external-link-alt"></i></a>
      </div>
      <div class="clocation">
          <h2>Student/Internships</h2>
          <p>Shop No-28, ModelTown, Phase-3,<br/>Bathinda-151001, Panjab, India</p>
          <a href="tel:+63277557700">+91 6265 578 975</a><br/>
          <a href="https://maps.google.com/?q=2nd+Floor+B3,+Bonifacio+High+Street,+Taguig,+Metro+Manila+1634,+Philippines" target="_blank">Get directions <i class="fas fa-external-link-alt"></i></a>
      </div>
      <div class="clocation">
          <h2>explore Opportuniies by location</h2>
          <p>E-7 Arera Colony,<br/>Bhopal-462016, Madhya Pradesh, India</p>
          <a href="tel:+63277557700">+91 6265 578 975</a><br/>
          <a href="https://maps.google.com/?q=2nd+Floor+B3,+Bonifacio+High+Street,+Taguig,+Metro+Manila+1634,+Philippines" target="_blank">Get directions <i class="fas fa-external-link-alt"></i></a>
      </div>
      



</div>



    </div>

    <Footer/>
    <Navbar/>
  </div>
  )
}

export default Career
