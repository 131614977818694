import React from 'react'
import Navbar from '../navbar/Navbar'
import Footer from '../footer/Footer'
import './values.css'

function Values() {
  return (
    <div>
        <div className='valuesdiv'>

        <div class="ctitle">Values & Culture</div>
        <div class="csubtitle">Our Values at Duniyape Technologies</div>
        <div className='valuesheading'>At Duniyape Technologies, we believe in creating innovative and impactful software solutions while maintaining a commitment to excellence and integrity. Our core values shape our daily operations, guiding our interactions with clients, partners, and team members alike.</div>


        <div className='valuesback'>
            <h1 className='valuesh1'>Innovation</h1>
            <img className='valuesimg' src='https://cdn.pixabay.com/photo/2020/05/31/19/53/light-bulb-5244001_1280.jpg' alt='img'/>
            <div className='valuesp'><p>We thrive on creativity and forward-thinking. Our team consistently pushes the boundaries of technology to create cutting-edge software solutions that meet the evolving needs of our clients.</p></div>
        </div>
        <div className='valuesback'>
            <img className='valuesimg' src='https://cdn.pixabay.com/photo/2023/06/08/12/37/work-8049516_1280.jpg' alt='img'/>
            <h1 className='valuesh1'>Customer-Centricity</h1>
            <div className='valuesp'><p>Our clients’ success is our success. We focus on understanding their needs, challenges, and goals to deliver tailor-made solutions that drive results and foster long-term partnerships.</p></div>
        </div>
        <div className='valuesback'>
            <div className='valuesp'><p>Trust and transparency are the cornerstones of how we do business. At every stage of the project, we ensure open communication and honesty, building strong, lasting relationships based on mutual respect.</p></div>
            <h1 className='valuesh1'>Integrity</h1>
            <img className='valuesimg' src='https://cdn.pixabay.com/photo/2018/06/11/11/16/businesswoman-3468308_1280.jpg' alt='img'/>
        </div>
        <div className='valuesback'>
            <h1 className='valuesh1'>Quality Excellence</h1>
            <img className='valuesimg' src='https://cdn.pixabay.com/photo/2020/05/22/21/44/review-5207277_1280.jpg' alt='img'/>
            <div className='valuesp'><p>We are committed to delivering the highest standards of quality in every project. Our attention to detail and rigorous testing ensure that we build robust, scalable, and efficient software solutions.</p></div>
        </div>
        <div className='valuesback'>
            <img className='valuesimg' src='https://cdn.pixabay.com/photo/2016/11/29/06/16/kindle-1867751_1280.jpg' alt='img'/>
            <h1 className='valuesh1'>Continuous Learning</h1>
            <div className='valuesp'><p>The tech world is ever-changing, and so are we. We foster a culture of learning, encouraging our team to stay updated on the latest technologies and methodologies to deliver innovative solutions.</p></div>
        </div>


        <div class="ccsubtitle">Our Culture at Duniyape Technologies</div>
        <div className='valuesheading'>We believe that a strong company culture is the backbone of innovation and productivity. At Duniyape Technologies, we foster a collaborative, diverse, and dynamic work environment where every team member feels empowered to contribute and grow.</div>


        <div className='valuesback'>
            <h1 className='valuesh1'>Collaborative Environment</h1>
            <img className='valuesimg' src='https://cdn.pixabay.com/photo/2018/03/10/12/00/teamwork-3213924_1280.jpg' alt='img'/>
            <div className='valuesp'><p>We work together as one team, where every voice is valued, and ideas flow freely. Collaboration is central to our problem-solving approach, and we encourage cross-functional teams to share knowledge and insights.</p></div>
        </div>
        <div className='valuesback'>
            <img className='valuesimg' src='https://cdn.pixabay.com/photo/2019/12/13/02/55/africans-4691992_1280.jpg' alt='img'/>
            <h1 className='valuesh1'>Diversity and Inclusion</h1>
            <div className='valuesp'><p>Our global team is our greatest asset. We embrace diversity of thought, background, and experience, fostering an inclusive environment where creativity thrives and innovation happens.</p></div>
        </div>
        <div className='valuesback'>
            <div className='valuesp'><p>We understand the importance of maintaining a healthy work-life balance. By offering flexible work schedules, remote working opportunities, and wellness programs, we ensure that our team stays energized and motivated.</p></div>
            <h1 className='valuesh1'>Work-Life Balance</h1>
            <img className='valuesimg' src='https://cdn.pixabay.com/photo/2020/06/23/20/43/work-life-balance-5333818_1280.jpg' alt='img'/>
        </div>
        <div className='valuesback'>
            <h1 className='valuesh1'>Growth and Development</h1>
            <img className='valuesimg' src='https://cdn.pixabay.com/photo/2020/01/26/20/14/computer-4795762_1280.jpg' alt='img'/>
            <div className='valuesp'><p>At Duniyape Technologies, we are committed to the continuous personal and professional development of our team. We provide mentorship, ongoing learning opportunities, and career advancement paths to help our employees reach their full potential.</p></div>
        </div>
        <div className='valuesback'>
            <img className='valuesimg' src='https://cdn.pixabay.com/photo/2018/06/03/02/31/lightbulb-3449671_1280.jpg' alt='img'/>
            <h1 className='valuesh1'>Innovation-Driven Mindset</h1>
            <div className='valuesp'><p>We inspire creativity and innovation at every level of the organization. Our team is encouraged to think outside the box, experiment with new ideas, and bring fresh solutions to the table, helping us stay ahead in a fast-paced industry.</p></div>
        </div>


        </div>
      

        <Footer/>
      <Navbar/>
    </div>
  )
}

export default Values
