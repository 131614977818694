import React from 'react'
import Navbar from '../navbar/Navbar'
import Footer from '../footer/Footer'
import './story.css'

function Story() {
  return (
    <div>

        <div className='storydiv'>

            <div className='storytitle'>Our Story</div>

            <div className='storydis'>The Duniyape Technologies Journey</div>

            <div className='storyp'>Duniyape Technologies was founded with a bold vision: to bridge the gap between businesses and technology, empowering organizations to navigate an increasingly digital world. It all began in [Year of Establishment], when a group of passionate engineers and technology enthusiasts came together with a shared dream — to create software solutions that solve real-world problems while driving innovation across industries.</div>
            <div className='storyp'>From the start, our goal was clear: to leverage cutting-edge technologies and deliver custom-built software solutions that would transform how companies operate and compete in their markets. What started as a small team working on modest projects soon grew into a thriving company with a global presence, delivering exceptional software products to clients across various industries.</div>
            <div className='storyp'>At Duniyape Technologies, we pride ourselves on our unique ability to blend deep technical expertise with creative problem-solving. Our approach to software development is guided by a desire to make an impact, not just in the tech world, but in the lives of our clients and the communities we serve.</div>

            <div className='storyline'></div>

            <div className='storydis'>A Commitment to Innovation</div>

            <div>As technology continues to evolve at an unprecedented pace, we remain committed to staying ahead of the curve. We constantly challenge ourselves to push the limits of what’s possible by exploring emerging technologies like AI, blockchain, IoT, and cloud computing. Whether it's developing enterprise-grade software, creating immersive mobile applications, or offering digital transformation consulting, our focus is always on innovation and excellence.</div>

            <div className='storyline'></div>

            <div className='storydis'>Our Growth and Impact</div>

            <div>Over the years, Duniyape Technologies has grown into a trusted partner for organizations around the world, from startups to Fortune 500 companies. Our success is not measured merely by our growth, but by the meaningful results we deliver to our clients. With each project, we help businesses streamline operations, enhance user experiences, and unlock new opportunities for growth.</div>

            <div className='storyline'></div>

            <div className='storydis'>Looking Ahead</div>

            <div>As we continue our journey, we remain guided by our core values: innovation, integrity, customer-centricity, and quality. Our vision is to shape the future of technology and continue creating impactful solutions that drive digital transformation. Every day, we are excited to take on new challenges and work alongside our clients as they turn their biggest ideas into reality.</div>

            <div>Duniyape Technologies is more than just a software development company — we are partners in progress, working to build a brighter, more connected world.</div>


        </div>
      
        <Footer/>
      <Navbar/>
    </div>
  )
}

export default Story
