import React from 'react'
import './footer.css'

function Footer() {
  return (
    <div className='fbody'>
      <div class="container">
   <div class="left-section">
    <h1>
     Start your free trial now.
    </h1>
    <p>
     Total protection has never been easier. Take advantage of our free 15-day trial and explore the most popular solutions for your business:
    </p>
    <ul>
     <li>
      Protect against malware with next-gen antivirus.
     </li>
     <li>
      Get unrivaled visibility with USB device control.
     </li>
     <li>
      Simplify your host firewall management.
     </li>
     <li>
      Receive real-time insights with automated threat intelligence.
     </li>
    </ul>
    <button class="btn-red">
     Request free trial →
    </button>
   </div>
   <div class="right-section">

<div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>

    <img alt="CrowdStrike logo" height="50" src="https://storage.googleapis.com/a1aa/image/vawOjp1dc67YEticcp1eLkBHDOEeMsUNSa4pyZ4s35nz1SnTA.jpg" width="150"/>

    <div class="footer-icons">
    <i class="fab fa-linkedin">
    </i>
    <i class="fab fa-twitter">
    </i>
    <i class="fab fa-facebook">
    </i>
    <i class="fab fa-instagram">
    </i>
    <i class="fab fa-youtube">
    </i>
   </div>

   </div>

    <div class="footer">
     <div>
      <h3>
       Our Services
      </h3>
      <ul>
       <li>
        <a href="#">
        App Development
        </a>
       </li>
       <li>
        <a href="#">
        Web/Software Development
        </a>
       </li>
       <li>
        <a href="#">
        Cloud Computing
        </a>
       </li>
       <li>
        <a href="#">
        Full Stack Development
        </a>
       </li>
       <li>
        <a href="#">
        E-Commerce Development
        </a>
       </li>
       <li>
        <a href="#">
        Digital Marketing Services
        </a>
       </li>
      </ul>
     </div>
     <div>
      <h3>
       Company
      </h3>
      <ul>
       <li>
        <a href="/">
         Home
        </a>
       </li>
       <li>
        <a href="/values">
        Values & Culture
        </a>
       </li>
       <li>
        <a href="/workflow">
        Workflow
        </a>
       </li>
       <li>
        <a href="/story">
        Our Story
        </a>
       </li>
       <li>
        <a href="/career">
        Careers
        </a>
       </li>
       {/* <li>
        <a href="#">
         CrowdStrike Marketplace
        </a>
       </li> */}
      </ul>
     </div>
     <div>
      <h3>
       Learn Policies
      </h3>
      <ul>
      <li>
        <a href="#">
         Support
        </a>
       </li>
       <li>
        <a href="/policy">
         Privacy Policy
        </a>
       </li>
       <li>
        <a href="/policy">
         Terms of Use
        </a>
       </li>
       
       {/* <li>
        <a href="#">
         Tech Hub
        </a>
       </li>
       <li>
        <a href="#">
         View all resources
        </a>
       </li> */}
      </ul>
     </div>
    </div>
    <div class="contact-buttons">
    <a href="/contact"><button class="btn-outline">
      Contact Us →
     </button></a>
     <button class="btn-outline">
      Experienced a breach? →
     </button>
    </div>
   </div>
  </div>
  <div class="footer-bottom">
   
   <p>
    Copyright © 2024
    <a href="/contact">
     Contact Us
    </a>
    <a href="#">
     Privacy
    </a>
    <a href="#">
     Cookies
    </a>
    <a href="#">
     Your Privacy Choices
    </a>
    <a href="#">
     Terms of Use
    </a>
    <a href="#">
     Accessibility
    </a>
   </p>
  </div>
    </div>
  )
}

export default Footer
