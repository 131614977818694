import React from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import './imagescroll.css'

function ImageScroll() {

    const settings = {
        dots: true,
        infinite: true,
        className: "center",
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        centerMode: true,
        centerPadding: "300px",
        pauseOnHover: false,

        responsive: [
            {
              breakpoint: 1024,
              settings: {
                dots: true,
        infinite: true,
        className: "center",
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        centerMode: true,
        centerPadding: "300px",
        pauseOnHover: false

              }
            },
            {
              breakpoint: 600,
              settings: {
                dots: true,
        infinite: true,
        className: "center",
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        centerMode: true,
        centerPadding: "10px",
        pauseOnHover: false

              }
            },
            {
              breakpoint: 480,
              settings: {
                dots: true,
        infinite: true,
        className: "center",
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        centerMode: true,
        centerPadding: "10px",
        pauseOnHover: false

              }
            }
          ]
      };

      const settings2 = {
        // dots: true,
        infinite: true,
        slidesToShow: 3,
        // slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 0,
        speed: 5000,
        cssEase: "linear",
        pauseOnHover: false
      };

  return (
    <div style={{
        width:'100vw',
        overflow:'hidden'
      }}>

<div className="slider-container">


<Slider {...settings}>
        <div>
            <img className='scrollimg' src='https://cdn.pixabay.com/photo/2021/08/05/12/36/software-development-6523979_1280.jpg'/>
        </div>
        <div>
            <img className='scrollimg' src='https://cdn.pixabay.com/photo/2024/04/05/05/17/technology-8676540_1280.jpg'/>
        </div>
        <div>
            <img className='scrollimg' src='https://cdn.pixabay.com/photo/2020/03/11/15/13/engineer-4922413_1280.jpg'/>
        </div>
        <div>
            <img className='scrollimg' src='https://cdn.pixabay.com/photo/2020/03/11/15/13/engineer-4922413_1280.jpg'/>
        </div>
        <div>
            <img className='scrollimg' src='https://cdn.pixabay.com/photo/2020/03/11/15/13/engineer-4922413_1280.jpg'/>
        </div>
        <div>
            <img className='scrollimg' src='https://cdn.pixabay.com/photo/2020/03/11/15/13/engineer-4922413_1280.jpg'/>
        </div>
        <div>
            <img className='scrollimg' src='https://cdn.pixabay.com/photo/2020/03/11/15/13/engineer-4922413_1280.jpg'/>
        </div>
        
      </Slider>


      <Slider {...settings2} style={{margin:'30px 0px'}}>
      <div>
            <img className='scrollimg2' src='https://cdn.pixabay.com/photo/2020/03/11/15/13/engineer-4922413_1280.jpg'/>
        </div>
        <div>
            <img className='scrollimg2' src='https://cdn.pixabay.com/photo/2020/03/11/15/13/engineer-4922413_1280.jpg'/>
        </div>
        <div>
            <img className='scrollimg2' src='https://cdn.pixabay.com/photo/2020/03/11/15/13/engineer-4922413_1280.jpg'/>
        </div>
        <div>
            <img className='scrollimg2' src='https://cdn.pixabay.com/photo/2020/03/11/15/13/engineer-4922413_1280.jpg'/>
        </div>
        <div>
            <img className='scrollimg2' src='https://cdn.pixabay.com/photo/2020/03/11/15/13/engineer-4922413_1280.jpg'/>
        </div>
        <div>
            <img className='scrollimg2' src='https://cdn.pixabay.com/photo/2020/03/11/15/13/engineer-4922413_1280.jpg'/>
        </div>
        <div>
            <img className='scrollimg2' src='https://cdn.pixabay.com/photo/2020/03/11/15/13/engineer-4922413_1280.jpg'/>
        </div>
      </Slider>
    </div>
      
    </div>
  )
}

export default ImageScroll
