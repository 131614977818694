import React from 'react'
import './contact.css'
import Navbar from '../navbar/Navbar'
import Footer from '../footer/Footer'

function Contact() {
  return (
    <div>



      <div className='contact'>
        <img className='contactimg' src='https://images.pexels.com/photos/1391499/pexels-photo-1391499.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500' alt='img'/>
        <img className='contactimg1' src='https://mewingemoji.com/wp-content/uploads/2024/08/cute-girl-dp-18.jpg' alt='img'/>
        <img className='contactimg2' src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSD0NvMEFA0lRMAY_0xaTfU2NITborRxls0EQ&s' alt='img'/>
        <img className='contactimg3' src='https://images.pexels.com/photos/1391499/pexels-photo-1391499.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500' alt='img'/>
        <img className='contactimg4' src='https://images.pexels.com/photos/1391499/pexels-photo-1391499.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500' alt='img'/>
        <img className='contactimg5' src='https://images.pexels.com/photos/1391499/pexels-photo-1391499.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500' alt='img'/>

        <div class="decorative-circle decorative-circle-1"></div>
    <div class="decorative-circle decorative-circle-2"></div>
    <div class="decorative-circle decorative-circle-3"></div>
    <div class="decorative-circle decorative-circle-4"></div>
    <div class="decorative-circle decorative-circle-5"></div>
    <div class="decorative-circle decorative-circle-6"></div>

    <div class="title">Contact our support teams</div>
    <div class="subtitle">Questions, bug reports, feedback – we’re here for it all.</div>

<div class="contactcontainer">
    
        <div class="location">
            <h2>Email Support</h2>
            <p>You can ask us your query <br/>through email.</p>
            <a href="tel:+61292621443">Support@duniyape.in</a><br/>
            <a href="https://maps.google.com/?q=Level+6,+341+George+Street,+Sydney,+NSW+2000,+Australia" target="_blank">Go to email <i class="fas fa-external-link-alt"></i></a>
        </div>
        <div class="location">
            <h2>Quick Chat</h2>
            <p>You can also connect with us<br/> via WhatsApp.</p>
            <a href="tel:+918037012649">+91 6265 578 975</a><br/>
            <a href="https://maps.google.com/?q=3rd+Floor,+Sunriver,+Wework,+Embassy+Golf+Links+Business+Park,+Domlur,+Bangalore+-+560071" target="_blank">Open Whatsapp <i class="fas fa-external-link-alt"></i></a>
        </div>
        <div class="location">
            <h2>Bathinda (HQ)</h2>
            <p>Shop No-28, ModelTown, Phase-3,<br/>Bathinda-151001, Panjab, India</p>
            <a href="tel:+63277557700">+91 6265 578 975</a><br/>
            <a href="https://maps.google.com/?q=2nd+Floor+B3,+Bonifacio+High+Street,+Taguig,+Metro+Manila+1634,+Philippines" target="_blank">Get directions <i class="fas fa-external-link-alt"></i></a>
        </div>
        <div class="location">
            <h2>Bhopal</h2>
            <p>E-7 Arera Colony,<br/>Bhopal-462016, Madhya Pradesh, India</p>
            <a href="tel:+63277557700">+91 6265 578 975</a><br/>
            <a href="https://maps.google.com/?q=2nd+Floor+B3,+Bonifacio+High+Street,+Taguig,+Metro+Manila+1634,+Philippines" target="_blank">Get directions <i class="fas fa-external-link-alt"></i></a>
        </div>
        



</div>



      </div>

      <Footer/>
      <Navbar/>
    </div>
  )
}

export default Contact
