import React from 'react'
import './imageview.css'

function ImageView() {
  return (
    <div>

        <div className='imageview1'></div>
        <div className='imageview2'></div>

        <div className='imagediv'>

          <div class="flip-box">
            <div class="flip-box-inner">
              <div className='imageview3 imageitem flip-box-front'></div>
              <div class="flip-box-back flipback1">
                <h2>App Development</h2>
                <p className='flipbackp'>Offering user-friendly and adaptable android/iOS applications all around the globe with 100% efficient services.</p>
    <div class="contact-buttons">
    <a href="/contact"><button class="btn-outline">
      More Details →
     </button></a>
    </div>
              </div>
            </div>
          </div>

          <div class="flip-box">
            <div class="flip-box-inner">
              <div className='imageview4 imageitem flip-box-front'></div>
              <div class="flip-box-back flipback2">
                <h2>Web/Software Development</h2>
                <p className='flipbackp'>We develelop customised web applications solutions with State of Art technology as per your requirements with latest technologies to assisting you in best possible way.</p>
              </div>
            </div>
          </div>

          <div class="flip-box">
            <div class="flip-box-inner">
              <div className='imageview5 imageitem flip-box-front'></div>
              <div class="flip-box-back flipback3">
                <h2>Cloud Computing</h2>
                <p className='flipbackp'>We provide cloud computing services—including servers, storage, databases and intelligence—over the internet to offer faster innovation, flexible resources, and economies of scale.</p>
              </div>
            </div>
          </div>

          <div class="flip-box">
            <div class="flip-box-inner">
              <div className='imageview6 imageitem flip-box-front'></div>
              <div class="flip-box-back flipback4">
                <h2>Full Stack Development</h2>
                <p className='flipbackp'>Dealing with both front and backend we offrer full stack development services to provide everything under one roof.</p>
              </div>
            </div>
          </div>

          <div class="flip-box">
            <div class="flip-box-inner">
              <div className='imageview7 imageitem flip-box-front'></div>
              <div class="flip-box-back flipback5">
                <h2>E-Commerce Development</h2>
                <p className='flipbackp'>We offer innovative customised Mobile App ( android and iOS ) development services with cross platform capabilities.</p>
              </div>
            </div>
          </div>

          <div class="flip-box">
            <div class="flip-box-inner">
              <div className='imageview8 imageitem flip-box-front'></div>
              <div class="flip-box-back flipback6">
                <h2>Digital Marketing Services</h2>
                <p className='flipbackp'>Attract new audiences and boost your brand with our Digitial Marketing expertise.As a leading digital marketing firm, we help you achieve all these goals and more.</p>
              </div>
            </div>
          </div>

        </div>

        {/* <div class="flip-box">
  <div class="flip-box-inner">
    <div class="flip-box-front"></div>
    <div class="flip-box-back">
      <h2>Paris</h2>
      <p>What an amazing city</p>
    </div>
  </div>
</div> */}
      
    </div>
  )
}

export default ImageView
